<template>
    <div class="more">
        
      <div class="title cursor" @click="path('/newsCenter/index/0')">
          
        <img src="../../assets/index/arrow-more.png" style="margin-right: 0.75rem;width: 1.25rem;height: 1.25rem;"/> <div class="see">{{lang.index_view_more}}</div>
        </div>
    </div>
  </template>
  
  <script>
	  import {
	  	mapState
	  } from 'vuex'
  export default {
    name: "more",
	computed: {
		...mapState({
		  lang: state => state.user.lang,
		})
	},
    props: {
    },
    data() {
      return {}
    },
		methods: {
			path(routePath){
				this.$router.push(routePath); 
			}
			
		}
  };
  </script>
  
  <style scoped lang="less">
  
    .more{
      background-color: #fff;
      width: 100%;
      padding: 1.875rem 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      
      .title{
        width: 10rem;
        height: 3.44rem;
        color: #333333;
        border: 1px solid #333333;
        display: flex;
        justify-content: center;
        align-items: center;
        .see{
          font-family: MicrosoftYaHei;
          font-size: 1rem;
          color: #333333;
        }
      }
    }
  </style>
  