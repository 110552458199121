<template>
    <div class="inHuiFa">
        
      <div class="title">
          <p class="frist">{{lang.index_huifa_into}}</p>
          <p class="second">{{lang.index_huifa_into_info}}</p>
      </div>
      <div class="list" id="list-box">
        <div class="slider-wrapper" id="list">
            
          <div class="item cursor" @click="path('/enteringHuifa/index/1')">
            <img src="../../assets/index/huifa-introduce.png" style="width: 5.25rem;height: 5.25rem;margin-top: 3.125rem;"/> 
            <div class="title">{{lang.index_huifa_intro}}</div>
            <p class="content line-two" style="width: 12.06rem;">{{lang.index_huifa_intro_info}}</p>
          </div>
          <div class="item cursor" @click="path('/enteringHuifa/index/2')">
            <img src="../../assets/index/huifa-note.png" style="width: 5.25rem;height: 5.25rem;margin-top: 3.125rem;"/> 
            <div class="title">{{lang.index_huifa_big_news}}</div>
            <div class="content line-two">{{lang.index_huifa_big_news_info}}</div>
          </div>
          <div class="item cursor" @click="path('/enteringHuifa/index/3')">
            <img src="../../assets/index/company-culture.png" style="width: 5.25rem;height: 5.25rem;margin-top: 3.125rem;"/> 
            <div class="title">{{lang.index_huifa_culture}}</div>
            <div class="content line-two">{{lang.index_huifa_culture_info}}</div>
          </div>
          <div class="item cursor" @click="path('/enteringHuifa/index/4')">
            <img src="../../assets/index/company-honor.png" style="width: 5.25rem;height: 5.25rem;margin-top: 3.125rem;"/> 
            <div class="title">{{lang.index_huifa_honor}}</div>
            <div class="content line-two">{{lang.index_huifa_honor_info}}</div>
          </div>
          <div class="item cursor" @click="path('/enteringHuifa/index/5')">
            <img src="../../assets/index/company-5.png" style="width: 5.25rem;height: 5.25rem;margin-top: 3.125rem;"/> 
            <div class="title">{{lang.index_huifa_welfare}}</div>
            <div class="content line-two">{{lang.index_huifa_welfare_info}}</div>
          </div>
          <div class="item cursor" @click="path('/enteringHuifa/index/6')">
            <img src="../../assets/index/company-6.png" style="width: 5.25rem;height: 5.25rem;margin-top: 3.125rem;"/> 
            <div class="title">{{lang.index_huifa_publication}}</div>
            <div class="content line-two" :style="{WebkitBoxOrient: 'vertical'}">{{lang.index_huifa_publication_info}}</div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
	  import {
	  	mapState
	  } from 'vuex'
  export default {
    name: "inHuiFa",
	computed: {
		...mapState({
		  lang: state => state.user.lang,
		})
	},
    props: {
    },
    data() {
      return {}
    },
    mounted() {
    },
    methods: { 
      path(routePath) {
        this.$router.push(routePath); 
        
      }
    }
    
  };
  </script>
  <style scoped lang="less">
	/* 响应式布局 */
	@media (max-width: 768px) {
  
    .inHuiFa{
      
      .list{
        width: 35.03rem !important;
      }
    }
		
	}
	/* 响应式布局 */
	@media (max-width: 435px) {
  
    .inHuiFa{
      
      .list{
        width: 17.515rem !important;
      }
    }
		
	}
  
  .inHuiFa{
		background-color: #fff;
    width: 100%;
    padding: 3.72rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .title{
        .frist{
            font-family: SourceHanSansCN, SourceHanSansCN;
            font-weight: bold;
            font-size: 1.75rem;
            color: #333333;
            text-align: left;
            line-height: 1.75rem;
            text-align: center;
        }
        .second{
            font-size: 0.875rem;
            color: #333333;
            text-align: center;
        }
    }
    .list::-webkit-scrollbar-corner{
      width: 0;
    }

 
    .list::-webkit-scrollbar {
      width: 0.25rem;  /* 设置滚动条的宽度 */
      height: 0.25rem;  /* 设置滚动条的宽度 */
    }
    
    .list::-webkit-scrollbar-thumb {
        background: #D72822;    /* 设置滚动条滑块的颜色 */
        border-radius: 0.13rem  0.13rem  0.13rem  0.13rem;
    }
    
    .list::-webkit-scrollbar-track {
        background: #F5F5F5; /* 设置滚动条轨道的颜色 */
    }

    .list{
      width: 70.06rem;
      height: 22.31rem;
      display:inline;
      overflow-x: scroll;
      scroll-behavior: smooth; 
      .slider-wrapper {
        width: 100%;
        height: 20.31rem;
        display: flex;
        .item{
          width: 17.515rem;
          height: 20.31rem;
          border-style: solid;
          border-color: #E9E9E9;
          border-width: 1px 1px 1px 0px;
          flex: 0 0 auto;
          box-sizing: border-box;
          text-align: center;
          .title{
            font-weight: bold;
            font-size: 1.25rem;
            color: #333333;
            margin: 1rem 0;
          }
          .content{
            width: 12.06rem;
            margin: 0 auto;
          }
          
        }
        .item:first-of-type {
           border-left: 1px solid #E9E9E9;
        }
      }
    
    }
  }
  

  </style>
  