<template>
	<div class="">
		<Banner></Banner>
		<Product-Platform></Product-Platform>
		<Project-Layout></Project-Layout>
		<in-hui-fa></in-hui-fa>
		<News-center></News-center>
		<more></more>
	</div>
	
			
</template>

<script>
import Banner from '../../components/indexMain/Banner.vue';
import ProductPlatform from '../../components/indexMain/ProductPlatform.vue';
import ProjectLayout from '../../components/indexMain/ProjectLayout.vue';
import inHuiFa from '../../components/indexMain/inHuiFa.vue';
import NewsCenter from '../../components/indexMain/NewsCenter.vue';
import more from '../../components/indexMain/more.vue';
  export default {
    props: {
    },
	components:{
		Banner,
		ProductPlatform,
		ProjectLayout,
		inHuiFa,
		NewsCenter,
		more
	},
    data() {
      return {}
    },
  };
</script>

<style lang="less">
	
</style>