<template>
    <div class="NewsCenter">
        <div class="title">
            <p class="frist">{{lang.index_news_center}}</p>
            <p class="second">{{lang.index_news_info}}</p>
        </div>
        <div class="news">
          <div class="news-left ">
            <swiper :options="swiperOption" ref="swiperRef" class="top-swiper container-main">
              <swiper-slide class="banner-item" style="text-align: center; width: 42.5rem !important; height: 23.38rem;" v-for="(item, index) in bannerList" 
                :key="index">
                <div class="content cursor">
                  
                  <div class="Project-img">
                    <img :src="item.img" :alt="item.title" >
                  </div>
                </div>
                
              </swiper-slide>
              
              <div class="swiper-pagination" slot="pagination"></div> 
            </swiper>
          </div>
          <div class="news-right">
            <div class="news-list">
              <div class="card" v-for="(item,index) in newsList" :key="index">
                <div class="content-item cursor" @click="path('/newsCenter/see/'+item.id)">
                  <div class="title flex-column-center" style="width: 25%;">
                    <p>{{ item.add_time.substring(item.add_time.length-5, item.add_time.length) }}</p>
                    <p>{{ item.add_time.substring(0, 4) }}</p>
                  </div>
                  <div class="line1"></div>
                  <div class="title line-one" style="width: 75%;padding: 0 1.56rem;">
                    <p class="line-one">{{item.title}}</p>
                    <p class="line-one">{{item.description}}</p>
                  </div>
                </div>
                <div class="line"></div>
              </div>
              <!-- <div class="card">
                <div class="content" @click="path('/newsCenter/see/2')">
                  <div class="title flex-column-center" style="width: 25%;">
                    <p>09-20</p>
                    <p>2024</p>
                  </div>
                  <div class="title" style="width: 75%;padding-left: 1.56rem;">
                    <p>润农大宗采购新进展</p>
                    <p>润农大宗采购新进展</p>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="content" @click="path('/newsCenter/see/3')">
                  <div class="title flex-column-center" style="width: 25%;">
                    <p>09-20</p>
                    <p>2024</p>
                  </div>
                  <div class="line1"></div>
                  <div class="title" style="width: 75%;padding-left: 1.56rem;">
                    <p>润农大宗采购新进展</p>
                    <p>润农大宗采购新进展</p>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
    </div>
  </template>
  
  <script>
	import {
		Swiper,
		SwiperSlide
	} from 'vue-awesome-swiper'
	import { getIndexNewsBanner,getIndexNews } from '@/api/store.js'
	import 'swiper/css/swiper.css'
	import {
		mapState
	} from 'vuex'
	let vm=null
  export default {
    name: "NewsCenter",
    props: {
    },
	computed: {
		...mapState({
		  lang: state => state.user.lang,
		})
	},
		components: {
			Swiper,
			SwiperSlide
		},
		created(){
			this.getIndexNewsBannerFun()
			this.getIndexNewsFun()
			vm = this;
		},
    data() {
      return {
				swiperOption: {
					spaceBetween: 10,
					slidesPerView: 1,
					loop: true,
					navigation: {
            nextEl: '.swiper-button-next', // 下一个按钮的DOM元素引用或选择器选择器
            prevEl: '.swiper-button-prev', // 上一个按钮的DOM元素引用或选择器选择器
					},
          pagination: {  
            el: '.swiper-pagination',  
            clickable: true,  
          },  
					autoplay: {
            delay: 5000, // 轮播时间间隔为3000毫秒（3秒）
            disableOnInteraction: false, // 用户操作后是否停止自动轮播，设为false表示不停止
					},
					on: {
            click: function (){
              const realIndex = this.realIndex;
              vm.handleClickSlide(realIndex); 
            }
          }   
				},
				bannerList: [],
        newsList: []
      }
    },
		methods: {
			handleClickSlide(index) {
				// let id=this.bannerList[index].id
				let url = this.bannerList[index].url
				this.path(url)
			},
			getIndexNewsBannerFun(){
        this.bannerList = []
				getIndexNewsBanner().then(res=>{
					this.bannerList = res.data.banner
				})
			},
			getIndexNewsFun(){
        this.newsList = []
				getIndexNews().then(res=>{
					this.newsList = res.data.news_list
				})
			},
			path(routePath){
				this.$router.push(routePath); 
			}
			
		}
  };
  </script>
  
  <style lang="less" scoped>
    ::v-deep .swiper-pagination-bullet-active{
      width: 1.5rem !important;
      background-color: #000 !important;
    }
    ::v-deep .swiper-pagination-bullet{
      width: 1rem;
      border-radius: 1rem;
      opacity: 0.6 !important;
      background-color: #fff;
    }
    ::v-deep .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets{
      left: -44%;
    }
      
      /* 响应式布局 */
      
      @media (max-width: 430px) {
      .container-main{
          height: 12rem;
        }
      }
      @media (max-width: 768px) {
        .Project-img img{
          width: 100% !important;
        }
        .NewsCenter .news{
          width: 100% !important;
          flex-direction: column !important;
          .news-left{
            width: 100% !important;
            padding: 0 3% !important;
            // position: absolute;
          }
          .news-right{
            width: 100% !important;
            .news-list{
              padding-top: 1rem !important;
              width: 100% !important;
              .card{
                margin: 0.1875rem auto !important;
                width: 90% !important;
                .content-item{
                  width: 100% !important;
                }
              }
            }
          }
        }
      }
    .NewsCenter{
        background-color: #fff;
        min-height: 31.25rem;
        width: 100%;
        padding: 1.875rem 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .title{
            .frist{
                font-family: SourceHanSansCN, SourceHanSansCN;
                font-weight: bold;
                font-size: 1.75rem;
                color: #333333;
                text-align: left;
                line-height: 1.75rem;
                text-align: center;
            }
            .second{
                font-size: 0.875rem;
                color: #333333;
                text-align: center;
            }
        }
        .news{
          min-height: 23.3rem;
          display: flex;
          margin-top: 1.875rem;
          justify-content: center;
          align-items: center;
          .news-left{
            margin: 0 0.4375rem;
            width: 42.5rem;
          }
          .news-right{
            width: 27.3rem;
            margin: 0 0.4375rem;
            .news-list{
              width: 100%;
              height: 23.3rem;
              display: flex;
              justify-content: space-between;
              flex-direction: column;
              .card{
                  width: 27.3rem;
                  height: 7.68rem;
                  border: 0;
                  margin: 0.1875rem 0.4375rem;
                  box-shadow: 0 0 0.4375rem 0 #F0F0F0;
                  .content-item{
                    height: 7.5rem;
                    width: 27.3rem;
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    .line1{
                      width: 0.125rem;
                      height: 5.69rem;
                      background: #FAFAFA;
                    }
                      .title{
                        font-family: SourceHanSansCN, SourceHanSansCN;
                        font-weight: 400;
                        font-size: 1rem;
                        color: #999999;
                        text-align: left;
                        font-style: normal;
                        text-transform: none;
                        p:first-child { 
                          font-weight: bold;
                          font-size: 1.25rem;
                          color: #333333;
                         }
                      }
                  }
                  .content-item::after{
                    content: "";
                    height: 0.25rem;
                    width: 100%;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    background: #B60914;
                    transform: scaleX(0);
                    transform-origin: bottom right;
                    transition: transform 800ms ease-in-out;
                  }
                  .content-item:hover::after{
                    transform: scaleX(1);
                    transform-origin: bottom left;
                  }
              }
            }
            
          }
        }
    }
    .flex-column-center{
      display: flex;flex-direction: column;justify-content: center;align-items: center;
    }
</style>
  