<template>
	<div class="banner">
		<swiper :options="swiperOption" ref="swiperRef" class="top-swiper">
			<swiper-slide class="banner-item" style="text-align: center;" v-for="(item, index) in bannerList"
				:key="index">
				<img :src="item.img" alt="" style="width: 100%;">
				<!-- <div class="banner-title">
					<h1>企业愿景：幸福家庭每一餐</h1>
					<p>做推动中国食品安全的志愿者</p>
					<p>助力国民生活健康 健康生活</p>
				</div> -->
				
			</swiper-slide>
		</swiper>
	</div>
</template>
<script>
	import {
		Swiper,
		SwiperSlide
	} from 'vue-awesome-swiper'
	import { getIndexBanner } from '@/api/store.js'
	import 'swiper/css/swiper.css'

	export default {
		name: "banner2",
		components: {
			Swiper,
			SwiperSlide
		},
		props: {


		},
		created(){
			this.getIndexBannerFun()
		},
		data() {
			return {
				swiperOption: {
					spaceBetween: 10,
					slidesPerView: 1,
					loop: true,
					autoplay: {
					        delay: 5000, // 轮播时间间隔为3000毫秒（3秒）
					        disableOnInteraction: false, // 用户操作后是否停止自动轮播，设为false表示不停止
					},
				},
				bannerList: [{
					bannerUrl: ''
				}]
			}
		},
		methods: {
			getIndexBannerFun(){
				getIndexBanner().then(res=>{
					this.bannerList = res.data.banner
				})
			},
		}
	}
</script>

<style lang="less" scoped>
	.banner {
		text-align: center;
		// .banner-title{
		// 	width: 100%;
		// 	height: 100%;
		// 	position: absolute;
		// 	top: 0;
		// 	left: 0;
		// 	h1{
		// 		margin-top: 25%;
		// 		font-weight: bold;
		// 		font-size: 3.75rem;
		// 		color: #FFFFFF;
		// 		margin-bottom: 1rem;
		// 	}
		// 	p{
		// 		font-weight: 500;
		// 		font-size: 1.5rem;
		// 		color: #FFFFFF;
		// 		margin-bottom: 0.5rem;
		// 	}
		// }
		
	}
</style>