<template>
	<div class="ProjectLayout">
		<div class="title">
			<p class="frist">{{lang.index_project_layout}}</p>
			<p class="second">{{lang.index_project_info}}</p>
		</div>
		<div class="Project ">
			<swiper :options="swiperOptionBanner" ref="swiperRef" class="top-swiper container-main ">
				<swiper-slide class="banner-item" style="text-align: center;" v-for="(item, index) in project_list" 
					:key="index" >
					<div class="content cursor" >
						
						<div class="Project-img">
							<img :src="item.img" alt="" >
						</div>
						<div class="Project-content">
							<p class="Project-title">{{item.title}}</p>
							<!-- <p class="Project-into">{{item.description}}</p> -->
							<p class="more"><img src="@/assets/index/ProjectLayout_more.png" alt="">MORE</p>
						</div>

					</div>
					
				</swiper-slide>
				
			</swiper>
			<div class="swiper-button-prev" slot="button-prev" @click="prev"></div>
			<div class="swiper-button-next" slot="button-next" @click="next"></div>
		</div>
	</div>
</template>

<script>
	import {
		mapState
	} from 'vuex'
	import {
		Swiper,
		SwiperSlide
	} from 'vue-awesome-swiper'
	import 'swiper/css/swiper.css'
	//import 'swiper/css/navigation'; // 确保引入导航按钮样式
	import { getIndexProject } from "@/api/store"
	let vm=null
	export default {
		name: "ProjectLayout",
		computed: {
			...mapState({
			  lang: state => state.user.lang,
			}),
			swiper(){
				return this.$refs.swiperRef.$swiper
			}
		},
		components: {
			Swiper,
			SwiperSlide
		},
		data() {
			return {
				swiperOptionBanner: {
					spaceBetween: 10,
					slidesPerView: 1,
					loop: true,
					navigation: {
					          nextEl: '.swiper-button-next', // 下一个按钮的DOM元素引用或选择器选择器
					          prevEl: '.swiper-button-prev', // 上一个按钮的DOM元素引用或选择器选择器
					},
					autoplay: {
					        delay: 5000, // 轮播时间间隔为3000毫秒（3秒）
					        disableOnInteraction: false, // 用户操作后是否停止自动轮播，设为false表示不停止
					},
					on: {
					        click: function (){
					            const realIndex = this.realIndex;
								vm.handleClickSlide(realIndex); 
					        }
					    }            
				},
				/* bannerList: [{
					imgUrl: require("../../assets/index/ProjectLayout_img_1.png"),
					
				},{
					imgUrl: require("../../assets/index/ProjectLayout_img_2.png"),
				}], */
				project_list:[]
			}
		},
		created() {
			this.getIndexProject()
			vm = this;
		},
		methods:{
			prev(){
				this.swiper.slidePrev()
			},
			next(){
				this.swiper.slideNext()
			},
			getIndexProject(){
				getIndexProject().then(res=>{
					this.project_list = res.data.project_list
				})
			},
			handleClickSlide(index) {
				let id=this.project_list[index].id
				let project_type=this.project_list[index].project_type
				this.path('/projectLayout/see/'+id + '?type=' + project_type)
			},
			path(routePath){
				 this.$router.push(routePath); 
			}
		}

	}
</script>

<style lang="less">
	.ProjectLayout {
		width: 100%;
		margin-top: 2.375rem;
		background-image: url('../../assets/index/ProjectLayout_bj.png');
		background-size: 100% 100%;
		height: 658px;
		padding-top: 1.875rem;
		

		/* display: flex;
        justify-content: center;
        align-items: center; */
		.title {
			
			margin-bottom: 1.9375rem;
			.frist {
				font-family: SourceHanSansCN, SourceHanSansCN;
				font-weight: bold;
				font-size: 1.75rem;
				color: #333333;
				line-height: 0px;
				text-align: center;
				line-height: 1.75rem;
			}

			.second {
				font-size: 0.875rem;
				color: #333333;
				line-height: 0px;
				text-align: center;
			}
		}
		.Project{
			 position: relative;
			 max-width: 1300px;
			 margin: auto;
			 margin-top: 3rem;
			 .container-main{
				 background-color: #fff;
				 .content{
					width: 100%;
					.Project-content{
						width: 33%;
						float: left;
						.Project-title{
							font-weight: bold;
							font-size: 1.75rem;
							color: #333333;
							margin-bottom: 0.5rem;
							line-height: 1.75rem;
							margin-top: 40%;
						}
						.Project-into{
							
							font-size: 1rem;
							color: #333333;
						}
						.more{
							width: 7.75rem;
							height: 3rem;
							background: #DE3641;
							line-height: 3rem;
							font-size: 1rem;
							color:#fff;
							margin: auto;
							margin-top: 3.5rem;
							img{
								width: 1.25rem;
								height: 1.25rem;
								margin-right: 0.625rem;
							}
						}
					}
					.Project-img{
						width: 67%;
						float: right;
						img{
							width: 100%;
						}
					}

				 }
			 }
			.swiper-button-next{
				background-image: url('../../assets/index/swiper_next.png');
				background-size: 100% 100%;
				width: 3.25rem;
				height: 3.25rem;
			}
			.swiper-button-prev{
				background-image: url('../../assets/index/swiper_prev.png');
				background-size: 100% 100%;
				width: 3.25rem;
				height: 3.25rem;
			}
			.swiper-button-prev:after, .swiper-container-rtl .swiper-button-next:after {
			    content: '';
			}
			.swiper-button-next:after, .swiper-container-rtl .swiper-button-prev:after {
			    content: '';
			}
		}

	}
</style>