<template>
	<div class="ProductPlatform">
		<div class="title">
			<p class="frist">{{lang.index_products_plat}}</p>
			<p class="second">{{lang.index_products_info}}</p>
		</div>
		<div class="Product container-main">
			<el-row :gutter="32">
				<el-col :xs="12" :sm="6" :lg="6" v-for="(cate,index) in cate_list" v-if="index<4">
					<div class="grid-content bg-purple cursor"  @click="path('/productplatform/index/'+cate.id)">
						<img :src="cate.img" width="100%"/>
						<div class="Product-type">
							<h5>{{cate.name}}</h5>
							<p></p>
						</div>
					</div>
				</el-col>
			</el-row>
			<el-row :gutter="32">
				<el-col :xs="12" :sm="6" :lg="6" v-for="(cate,index) in cate_list" v-if="index>3&&index<7">
					<div class="grid-content bg-purple cursor"  @click="path('/productplatform/index/'+cate.id)">
						<img :src="cate.img" width="100%"/>
						<div class="Product-type">
							<h5>{{cate.name}}</h5>
							<p></p>
						</div>
					</div>
				</el-col>
				<el-col :xs="12" :sm="6" :lg="6">
					<div class="grid-content cursor " @click="path('/productplatform/index/0')">
						<div class="more"><p><img src="@/assets/index/more_white.png"/>{{lang.index_view_more}}</p></div>
						<img class="more_bj" src="@/assets/index/more_bj.png" width="100%"/>
					</div>
				</el-col>
			</el-row>
		</div>
	</div>
</template>

<script>
	import { getIndexProductCate } from "@/api/store";
	import {
		mapState
	} from 'vuex'
	export default {
		name: "ProductPlatform",
		computed: {
			...mapState({
			  lang: state => state.user.lang,
			})
		},
		data() {
			return {
				cate_list:[]
			}
		},
		created(){
			this.getIndexProductCate()
		},
		methods: {
			getIndexProductCate(){
				getIndexProductCate().then(res=>{
					this.cate_list = res.data.cate_list
				})
			},
			path(routePath){
				this.$router.push(routePath); 
			}
			
		}
	};
</script>

<style lang="less">
	.ProductPlatform {
		background-color: #fff;
		width: 100%;
		padding: 1.875rem 0;

		/* display: flex;
        justify-content: center;
        align-items: center; */
		.title {
			margin-bottom: 1.9375rem;
			.frist {
				font-family: SourceHanSansCN, SourceHanSansCN;
				font-weight: bold;
				font-size: 1.75rem;
				color: #333333;
				line-height: 0px;
				text-align: center;
				line-height: 1.75rem;
			}

			.second {
				font-size: 0.875rem;
				color: #333333;
				line-height: 0px;
				text-align: center;
			}
		}
		.Product{
			 .el-row {
			    margin-bottom: 2rem;
			    &:last-child {
			      margin-bottom: 0;
			    }
			  }
			.grid-content{
				position: relative;
				border-radius: 0.5rem;
				width: 100%;
				height: 100%;
				overflow: hidden;
				img{
					transition: transform 0.3s ease; /* 平滑过渡效果 */
				}
				.Product-type{
					
					background-image: url('../../assets/index/ProductPlatform_bj.png');
					background-size: 100% 100%;
					position: absolute;
					top: 0;
					width: 100%;
					height: 100%;
					
					h5{
						font-size: 1.25rem;
						color: #fff;
						    position: absolute;
						    bottom: 1.825rem;
							left: 1.25rem;
					}
					p{
						width: 2.375rem;
						height: 0.125rem;
						background-color: #fff;
						bottom: 0.625rem;
						position: absolute;
						left: 1.25rem;
					}
				}
				.more{
					
					position: absolute;
					top: 0;
					width: 100%;
					height: 100%;
					p{
						width: 7.75rem;
						height: 3rem;
						background: #DE3641;
						color: #fff;
						font-size: 1rem;
						line-height: 3rem;
						border: none;
						margin: auto;
						margin-top: 8rem ;
						img{
							margin-left: 0.9375rem;
							width: 1.25rem;
							height: 1.25rem;
							margin-right: 0.625rem;
						}
					}
					
				}
				.more_bj{
					
				}
			}
			.bg-purple:hover img{
				 transform: scale(1.1); /* 放大10% */
			}
			
		}

	}
	@media (max-width: 768px) {
		.ProductPlatform .Product .el-row {
		    margin-bottom: 0;
		}
		.ProductPlatform .Product .el-row .el-col-xs-12 {
		        margin-bottom: 1.5625rem;
		}
		.ProjectLayout{
			padding-bottom: 3rem;
			height: auto !important;
		}
		.ProjectLayout .Project .container-main .content .Project-content{
			display: none;
		}
		
		
		/* .ProjectLayout .Project .container-main .content .Project-content .Project-title {
		    margin-top: 2rem;
		}
		.ProjectLayout .Project .container-main .content .Project-content .more {
		    margin-top: 1.5rem;
		} */
	}
</style>